<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_18.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Propriedade atingida em Campinas, zona rural de Mariana. Foto: A
            Sirene, 2020.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            title="Situação vivenciada pela comunidade devido à passagem da lama"
            titleColor="#000"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                As áreas de cotas mais baixas, situadas nas margens ao longo do
                Rio Gualaxo do Norte foram atingidas e devastadas pela lama de
                rejeitos proveniente do rompimento da barragem de Fundão. Com a
                ação do rejeito, muitas plantações foram destruídas,
                principalmente aquelas localizadas próximas aos cursos d’água
                por onde a lama seguiu. Áreas de pastagem também foram
                devastadas e o gado, atualmente, assim como observado na vizinha
                Pedras, pasta nos morros. Em Campinas, onde o rejeito passou
                pelas bordas do núcleo urbano, criações de galinhas foram
                levadas e hortas destruídas. Por ter ocorrido aumento
                substancial do volume das águas do Rio Gualaxo na época da
                passagem da lama, esse teve suas margens deslocadas, invadindo
                áreas usadas por alguns produtores para diversas atividades como
                pastagem, dessedentação animal, plantio em épocas de seca, entre
                outras.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Com o alargamento das margens do Rio Gualaxo, muitos moradores
                tiveram suas áreas produtivas reduzidas. Soma-se a isso o fato
                de as áreas próximas ao rio, em sua maioria, ainda possuírem
                lama sobre o solo natural, reduzindo ainda mais as áreas de
                cultivo. Houve ainda aumento significativo das áreas de erosão
                ao longo do rio, causando grande assoreamento.
              </p>
              <p>
                Assim, a tradição de cultivar a terra foi significativamente
                prejudicada pelo rompimento da barragem, e a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >soberania alimentar</router-link
                >
                foi atingida diante da dificuldade de cultivo dos próprios
                alimentos, livres de agrotóxicos e fertilizantes químicos.
                Conforme relatos da população local, diversas espécies arbóreas
                de grande porte, algumas centenárias, secaram de forma
                vertiginosa após a passagem da lama de rejeitos, o que pode ser
                observado nas árvores secas ao longo das margens do Rio Gualaxo.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_19.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de trecho do Rio do Norte com rejeitos de minério e árvores
            secas à margem. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_campinas_20.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de uma placa de sinalização com aviso de área de risco, tendo
            em segundo plano edificações residenciais. Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Parte dos moradores com idade mais avançada
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{
                    name: 'conteudos_tematicos_casas_desterritorializacao',
                  }"
                  >deixaram suas casas</router-link
                >
                e suas relações de amizade, a conexão com a localidade e com a
                natureza, e se mudaram para a sede de Mariana, devido ao trauma
                sofrido com o rompimento da barragem de Fundão. A parcela da
                população que permaneceu em Campinas depara-se com a perda das
                relações com as comunidades vizinhas devastadas pelo rejeito,
                não frequentam mais as celebrações e festividades nessas
                comunidades, nem mesmo recebem esses visitantes que
                tradicionalmente participavam dos festejos locais, consistindo
                em importantes trocas culturais.
              </p>
            </template>
          </Card>
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O isolamento físico temporário também causou grandes transtornos
                para a população de Campinas, que ficou sem acesso por pelo
                menos dois meses. Com a destruição de estradas e pontes, os
                moradores não puderam sair da comunidade, ou tiveram que
                utilizar caminhos alternativos pelas matas. A perda de acesso
                temporário impediu o escoamento de produtos e a chegada de
                insumos, assim como a realização de atividades produtivas
                durante o período, visto que a falta de acesso acarretou na
                perda da produção pela falta de manejo das áreas que ficaram
                isoladas.
              </p>
              <p>
                Após a passagem da lama, foram realizadas obras de reconstrução
                de pontes e estradas da região, havendo grande circulação de
                veículos na comunidade. Além dos transtornos gerados como
                barulho e falta de segurança, os moradores enfrentam o
                surgimento de trincas e rachaduras nas edificações em virtude do
                tráfego intenso de veículos pesados pelas vias. Os moradores
                relatam também sobre o aumento da circulação de pessoas
                estranhas, o que gera bastante insegurança, além do crescente
                índice de violência e roubos. Em consequência, o local que se
                caracterizava pela calmaria, se deparou com a perda de
                tranquilidade e do direito de manter portas e janelas abertas,
                de deixar suas
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_crianca_adolescente' }"
                  >crianças brincarem na rua.</router-link
                >
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="position-rel">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
          style="z-index: 2"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Soma-se a isso tudo, o receio vivenciado cotidianamente pelos
                moradores da região, incluindo Campinas, referente a um novo
                rompimento de barragem de mineração. Parte das propriedades
                rurais não foi completamente destruída pela passagem da lama,
                porém muitas casas e áreas de produção foram abandonadas pelo
                medo, e a comunidade convive com o êxodo populacional.
              </p>
              <p>
                Devido à grande evasão, principalmente de jovens e trabalhadores
                do campo, muitos agricultores têm enfrentado dificuldades em
                realizar atividades produtivas pela falta de mão de obra. Os
                produtores agrícolas (de hortaliças, grãos e olerícolas), assim
                como os da agroindústria familiar (queijo, requeijão, bolos e
                doces) que conseguem produzir em Campinas têm enfrentado grande
                dificuldade de comercialização devido à decadência ou devastação
                de comunidades vizinhas.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
          style="z-index: 2"
        >
          <v-img
            width="100%"
            src="../../assets/sobre_territorio_campinas_21.png"
          />
        </v-col>
      </v-row>

      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center pos-relative"
          style="z-index: 1"
        >
          <v-img
            class="position-abs"
            max-width="70%"
            src="../../assets/sobre_territorio_campinas_22.png"
          />
        </v-col>
        <v-col
          style="z-index: 1"
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Diante das dificuldades em realizar atividades econômicas, bem
                como da insegurança em relação à presença de material tóxico nos
                cursos d’água e no solo, somada a outras questões como danos nas
                edificações, Campinas sofre com a desvalorização imobiliária,
                sejam eles situados em cotas de menor altitude alcançados pela
                lama ou não.
              </p>
              <p>
                É importante lembrar ainda que a comunidade apresenta grande
                potencial para exploração de recursos minerais (maciços
                rochosos), porém, com as consequências do rompimento surge a
                incerteza quanto à exploração desses recursos internos aos
                terrenos, tendo em vista a decadência econômica e a perda da
                capacidade de realizar novos investimentos por parte dos
                moradores.
              </p>
              <p>
                A condição em que se encontra o subdistrito é de vulnerabilidade
                social e financeira, havendo empobrecimento ou decadência
                econômica da comunidade. O local que anteriormente possuía certa
                autonomia produtiva e alimentar, encontra dificuldade de
                comercialização de produtos pela possível presença de metais
                pesados, além do risco de consumo por parte da própria família.
              </p>
              <p>
                O rompimento da barragem afetou também as relações de trabalho,
                uma vez que alguns moradores desenvolviam parceria comercial e
                muitos comerciantes dependiam de comunidades vizinhas que foram
                atingidas pelo rompimento da barragem de Fundão. O comércio
                local também foi extremamente prejudicado, conforme relatado
                pelos donos de bares, que perdem suas mercadorias pelo
                vencimento do prazo de validade, em consequência principalmente
                da redução do turismo e evasão populacional, enfrentando a perda
                de renda pela falta de consumidores. Logo após o desastre, a
                comunidade ficou sem energia elétrica por um tempo e muitos
                comerciantes tiveram perda de alimentos, devido à falta de
                refrigeração. Os prejuízos vêm se somando e dificultando que os
                pequenos comerciantes mantenham suas atividades.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira <sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="container-fluid">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card" :style="{ zIndex: 4 }">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização de Campinas tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p>
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
@media (max-width: 820px) {
  .position-abs {
    top: 30%;
  }
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
